/* eslint-disable no-template-curly-in-string */
import Home from "./components/pages/home";
import Product from "./components/pages/product";
import Checkout from "./components/pages/checkout";
import PaypalCancel from "./components/pages/checkout/payment/paypal/cancel";
import PaypalSuccess from "./components/pages/checkout/payment/paypal/success";
import StripeSuccess from "./components/pages/checkout/payment/stipe/success";
import StripeCancel from "./components/pages/checkout/payment/stipe/cancel";
import Download from "./components/pages/download";

const routes = [
    {
        path: `/`,
        pathname: "/",
        exact: true,
        name: "Home",
        pageTitle: "Welcome to our bookstore for Math Olympiad preparation resources",
        id: "Home",
        component: Home
    },
    {
        path: `/product/:productKey`,
        pathname: "/product/:productKey",
        exact: true,
        name: "Product",
        id: "product",
        component: Product
    },
    {
        path: `/checkout/:productKey`,
        pathname: "/checkout/:productKey",
        exact: true,
        name: "Checkout",
        pageTitle: "Checkout",
        id: "checkout",
        component: Checkout
    },
    {
        path: `/payment/paypal/success`,
        pathname: `/payment/paypal/success`,
        exact: true,
        name: "Paypal Status",
        pageTitle: "Paypal Success",
        id: "paypalSuccess",
        component: PaypalSuccess
    },
    {
        path: `/payment/paypal/cancel`,
        pathname: `/payment/paypal/cancel`,
        exact: true,
        name: "Paypal Cancel",
        pageTitle: "Paypal Cancel",
        id: "paypalCancel",
        component: PaypalCancel
    },
    {
        path: `/payment/stripe/success`,
        pathname: `/payment/stripe/success`,
        exact: true,
        name: "Stripe Status",
        pageTitle: "Stripe Success",
        id: "stripeSuccess",
        component: StripeSuccess
    },
    {
        path: `/payment/stripe/cancel`,
        pathname: `/payment/stripe/cancel`,
        exact: true,
        name: "Stripe Cancel",
        pageTitle: "Stripe Cancel",
        id: "stripeCancel",
        component: StripeCancel
    },
    {
        path: `/download`,
        pathname: `/download`,
        exact: true,
        name: "Download Book",
        pageTitle: "Download",
        id: "download",
        component: Download
    }
];

export default routes;
