/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import MetaTags from "../../../metaTags";
import axios from "axios";
import PaymentComponent from "./payment";
import Loader from "../../partials/loader";
import Modal from 'react-modal';
import {CSSTransition} from "react-transition-group";

const apiUrl = process.env.REACT_APP_API;

function Checkout(props) {
    const {pageTitle} = props
    const {productKey} = props.match.params
    const [product, productState] = useState({})
    const [empty, emptyState] = useState(true);
    const [loader, loaderState] = useState(true)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [couponState, setCouponState] = useState({
        couponApplied: false,
        couponLoader: false,
        couponCode: '',
        error: false,
        errorMessage: ''
    })
    useEffect(() => {
        document.body.className = 'page-checkout';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {
        getProductData(productKey).then(res => {
            const {code, data} = res
            if (code === 200) {
                productState(data)
                emptyState(false);
                loaderState(false);
            } else {
                window.location.href = '/'
            }
        })
        return () => {
            productState({})
            emptyState(true);
        }
    }, [productKey]);

    const getProductData = async value => {
        const res = await axios.get(`${apiUrl}/store/books/${value}`);

        let {data} = res;

        if (data.error === null) {
            return {
                code: 200,
                data: data.payload
            }
        } else {
            return {
                code: 400
            }
        }
    }

    const applyCouponCode = async (bookId, couponCode) => {

        let postData = {
            bookId,
            coupon: couponCode
        }

        const res = await axios.post(`${apiUrl}/store/books/apply-coupon`, postData);

        let {data} = res

        if (data.error === null) {
            return data.payload
        } else {
            setCouponState(prevState => {
                return {
                    ...prevState,
                    couponLoader: false,
                    errorMessage: data.error.message,
                    error: true
                }
            })
        }
    }

    const onSubmit = () => {
        const {couponCode, error} = couponState
        if (couponCode && !error) {
            setCouponState(prevState => {
                return {
                    ...prevState,
                    couponLoader: true
                }
            })
            applyCouponCode(bookId, couponCode).then(res => {
                if (res) {
                    let {discount, price} = res
                    setIsOpen(false)

                    setCouponState(prevState => {
                        return {
                            ...prevState,
                            couponLoader: false,
                            couponApplied: true,
                        }
                    })

                    productState(prevState => {
                        return {
                            ...prevState,
                            discount: parseFloat(discount),
                            price
                        }
                    })
                }
            })
        } else {
            setCouponState(prevState => {
                return {
                    ...prevState,
                    couponLoader: false,
                    errorMessage: 'Coupon cannot be blank.',
                    error: true
                }
            })
        }
    }

    const {url, price, discount, shortDescription, name, id: bookId} = product
    const {error, errorMessage, couponCode, couponLoader, couponApplied} = couponState

    return (
        <>
            <MetaTags page="checkout"/>
            {loader && (<Loader/>)}
            {couponLoader && (<Loader type="transparent"/>)}
            <page-container>
                {!empty && (
                    <page-wrapper>
                        <h1 className="text-align-center color-black">{pageTitle}</h1>

                        <div className="checkout-list">
                            <div className="info-box text-align-left">
                                <div className="image">
                                    <img src={url} alt={name}/>
                                </div>
                                <div className="text-box">
                                    <div className="name">{name}</div>
                                    <div className="short-description">{shortDescription}</div>
                                </div>
                            </div>

                            <div className="price-box text-align-right">
                                {discount ? (
                                    <>
                                        <div className="product-price-box">
                                            <div className="product-price old-price">${price.toFixed(2)}</div>
                                            <div className="product-price new-price">${couponApplied ? discount : (price - discount).toFixed(2)}</div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="product-price-box">
                                        <div className="product-price">${price.toFixed(2)}</div>
                                    </div>
                                )}

                                <div className="price-total">
                                    <span>Total </span>
                                    <span>${couponApplied ? discount : (price - discount).toFixed(2)}</span>
                                </div>
                            </div>

                            <div className="coupon-box">
                                {!couponApplied ? (
                                    <div className="coupon-btn" onClick={() => {
                                        setIsOpen(true)
                                    }}>Do you have a coupon?
                                    </div>
                                ) : (
                                    <div className="coupon-code">
                                        <span>Coupon Code:</span>
                                        <span className="code">{couponCode}</span>
                                        <span className="clear-code" onClick={() => {
                                            setCouponState(prevState => {
                                                return {
                                                    ...prevState,
                                                    couponLoader: true,
                                                }
                                            })
                                            getProductData(productKey).then(res => {
                                                const {code, data} = res
                                                if (code === 200) {
                                                    setCouponState(prevState => {
                                                        return {
                                                            ...prevState,
                                                            couponLoader: false,
                                                            couponApplied: false,
                                                            couponCode: '',
                                                        }
                                                    })
                                                    productState(data)
                                                    loaderState(false);
                                                } else {
                                                    window.location.href = '/'
                                                }
                                            })
                                        }}>clear</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <PaymentComponent price={price} discount={discount} bookId={bookId}
                                          couponCode={couponApplied ? couponCode : ''}/>
                    </page-wrapper>
                )}
            </page-container>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => {
                    setIsOpen(false)
                }}
                contentLabel="Example Modal"
                portalClassName="modal modal-coupon"
                ariaHideApp={false}
            >

                <h2>Apply Coupon</h2>
                <div className={`field-row field-row-paypal`}>
                    <div className={`field-box field-box-paypal`}>
                        <input placeholder="Coupon Code"
                               className={`with-border ${error ? 'has-error' : ''}`}
                               type="text"
                               name="coupon" onChange={event => {
                            if (event.target.value === '') {
                                setCouponState(prevState => {
                                    return {
                                        ...prevState,
                                        couponCode: '',
                                        error: false,
                                        errorMessage: ''
                                    }
                                })
                            } else {
                                setCouponState(prevState => {
                                    return {
                                        ...prevState,
                                        error: false,
                                        errorMessage: '',
                                        couponCode: event.target.value
                                    }
                                })
                            }
                        }}/>
                        <CSSTransition
                            in={error}
                            timeout={300}
                            classNames="dropdown-transition"
                            unmountOnExit
                        >
                            <div
                                className="message-field-label message-error">{errorMessage}</div>
                        </CSSTransition>
                    </div>

                    <button className="btn btn-checkout bg-green color-white"
                            onClick={element => onSubmit(element)}>Apply
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default Checkout;
