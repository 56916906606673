/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import MetaTags from "../../../metaTags";
import axios from "axios";
import {Link} from "react-router-dom";
import InterestedForm from "../../partials/interestedForm";
import Loader from "../../partials/loader";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const apiUrl = process.env.REACT_APP_API;

function Home(props) {
    const {pageTitle} = props
    const [products, productsState] = useState([])

    const [state, setState] = useState({
        showLightBox: false,
        photoIndex: 0,
    })
    const [loader, loaderState] = useState(true)
    useEffect(() => {
        document.body.className = 'page-home';
        getProducts().then(res => {
            const {code, data} = res
            if (code === 200) {
                productsState(data)
                loaderState(false)
            }
        })
        return () => {
            document.body.className = '';
        }
    }, []);

    const getProducts = async () => {

        const res = await axios.get(`${apiUrl}/store/books`);

        let {data} = res;

        if (data.error === null) {
            return {
                code: 200,
                data: data.payload.rows
            }
        } else {
            return {
                code: 400
            }
        }
    }

    const handleImageClick = index => {
        setState(prevState => {
            return {
                ...prevState,
                photoIndex: index,
                showLightBox: true
            }
        })
    }

    const {
        showLightBox, photoIndex
    } = state

    return (
        <>
            <MetaTags page="home"/>
            {loader && (<Loader/>)}
            <page-container>
                <page-wrapper>
                    <h1 className="text-align-center color-orange">{pageTitle}</h1>

                    <div className="product-list">
                        {products.map((item, itemKey) => {
                            const {key, url, price, discount, shortDescr, isActive, name, id} = item
                            return (
                                <div className="product-item" key={itemKey}>
                                    <div className="product-image">
                                        <img src={url} alt={name} onClick={() => {
                                            handleImageClick(itemKey)
                                        }}/>
                                    </div>

                                    <div className="product-info">
                                        <div className="product-title">{name}</div>
                                        <div className="product-short-description"
                                             dangerouslySetInnerHTML={{__html: shortDescr.replace(/(\r\n|\n|\r)/gm, "<br />")}}/>
                                        {!isActive ? (
                                            <InterestedForm bookId={id}/>
                                        ) : discount ? (
                                            <>
                                                <div className="product-price-box">
                                                    <div className="product-price old-price">${price.toFixed(2)}</div>
                                                    <div
                                                        className="product-price new-price">${(price - discount).toFixed(2)}</div>
                                                </div>
                                                <Link to={`/product/${key}`}
                                                      className="btn btn-medium-extra color-white bg-green">Learn more and buy printable pdf book</Link>
                                            </>
                                        ) : (
                                            <>
                                                <div className="product-price-box">
                                                    <div className="product-price">${price.toFixed(2)}</div>
                                                </div>
                                                <Link to={`/product/${key}`}
                                                      className="btn btn-medium-extra color-white bg-green">Learn more and buy printable pdf book</Link>
                                            </>
                                        )}

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </page-wrapper>
            </page-container>

            {showLightBox && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={products[photoIndex].url}
                    onCloseRequest={() => setState(prevState => {
                        return {
                            ...prevState,
                            showLightBox: false,
                            photoIndex: 0
                        }
                    })}
                />
            )}
        </>
    )
}

export default Home;
