/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import Loader from "../../partials/loader";

const apiUrl = process.env.REACT_APP_API;
const qs = require('qs');

function Download() {
    const [loader, loaderState] = useState(true)

    const [state, setState] = useState({
        linkExpired: false,
        bookExpired: false,
        success: false,
        downloadUrl: '',
        title: '',
        description: ''
    })
    useEffect(() => {
        document.body.className = 'page-download';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        getDownloadData(params).then(res => {
            const {code, data} = res
            if (code === 200) {
                loaderState(false)
                const file = new Blob(
                    [data],
                    {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);

                setState(prevState => {
                    return {
                        ...prevState,
                        success: true,
                        downloadUrl: fileURL,
                        title: "Press button for Download",
                    }
                })
            } else {
                if (code === 412) {
                    loaderState(false)

                    setState(prevState => {
                        return {
                            ...prevState,
                            bookExpired: true,
                            title: "Too many download attempts.",
                            description: ""
                        }
                    })
                }

                if (code === 413) {
                    loaderState(false)

                    setState(prevState => {
                        return {
                            ...prevState,
                            linkExpired: true,
                            title: "Link you are using is expired.",
                            description: "No Worries! Just request an email with new download link!"
                        }
                    })
                }

                if (code === 415) {
                    window.location.href = '/'
                }
            }
        })
        return () => {
        }
    }, []);

    const getDownloadData = async (params) => {

        try {
            const res = await axios.get(`${apiUrl}/store/books/receive`, {
                params: params,
                responseType: 'blob'
            });

            let {data} = res;

            return {
                code: 200,
                data: data
            }
        } catch (error) {
            return {
                code: error.response.status
            }
        }
    }

    const generateNewEmail = async () => {
        loaderState(true)
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        const queryParams = {
            email: params.email,
            bookId: params.b
        }
        const res = await axios.post(`${apiUrl}/store/books/new-download-email/`, queryParams);

        const {data} = res

        if (data.error === null) {
            loaderState(false)
            setState(prevState => {
                return {
                    ...prevState,
                    linkExpired: false,
                    title: 'Email send successfully',
                    description: 'please check your email!'
                }
            })
        } else {
            loaderState(false)
        }
    }

    let {linkExpired, bookExpired, title, description, success, downloadUrl} = state

    return (
        <>
            {loader && (<Loader type="transparent"/>)}

            <page-container>
                <page-wrapper>
                    <h1 className="color-cornflower">{title}</h1>
                    {description && (<div className="description">{description}</div>)}
                    {success && (
                        <>
                            <div className="description"/>
                            <a onClick={() => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        success: false,
                                        downloadUrl: '',
                                        title: "Thanks for Downloading!"
                                    }
                                })
                            }} href={downloadUrl} rel="noreferrer" className="btn btn-medium color-white bg-green"
                               target="_blank">Download</a>
                        </>

                    )}
                    {linkExpired && (
                        <div className="btn btn-medium bg-green color-white" onClick={generateNewEmail}>Request for
                            New Book</div>
                    )}
                    {bookExpired && (
                        <div className="additional-data">Please contact our support: <a
                            href="mailto:name@mail.com">support@mathi.co</a>.</div>
                    )}
                </page-wrapper>
            </page-container>
        </>
    )
}

export default Download;
