/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Tabs, {TabPane} from 'rc-tabs';
import PaypalPayment from './paypal'
import StripePayment from './stipe'
import {ReactComponent as PaypalIcon} from "../../../../Assets/images/svg/icon-paypal.svg";
import {ReactComponent as CreditCardsIcon} from "../../../../Assets/images/svg/icon-credit-cards.svg";

const PaymentComponent = (props) => {
    const {bookId, price, discount, couponCode} = props
    const [state, setState] = useState({
        errorMessage: '',
        loader: false,
        currentPayOption: 'paypal',
        component: {
            paypal: PaypalPayment,
            stripe: StripePayment
        }
    })
    const [payOptions] = useState([
        {
            key: 'paypal',
            text: null
        },
        {
            key: 'stripe',
            text: 'Credit Card'
        }
    ])
    useEffect(() => {

        return () => {
        }
    }, []);

    function tabsChangeCallback(key) {
        setState(prevState => {
            return {
                ...prevState,
                currentPayOption: key
            }
        })
    }

    function setTabTitle(key, text) {
        return (
            <>
                {key === 'paypal' ? (<PaypalIcon/>) : (<CreditCardsIcon/>)}
                {text ? (<span>{text}</span>) : ''}
            </>
        )
    }

    let {currentPayOption, component} = state

    return (
        <>
            <div className="payment-box">
                <h2>Choose payment method</h2>
                <Tabs activeKey={currentPayOption} animated={false} destroyInactiveTabPane={true}
                      onChange={tabsChangeCallback}>
                    {payOptions.map((item) => {
                        let {key, text} = item
                        let Component = component[key]
                        return (
                            <TabPane tab={setTabTitle(key, text)} key={key}>
                                {React.createElement(Component, {
                                    bookId: bookId,
                                    price: price,
                                    discount: discount,
                                    couponCode: couponCode
                                })}
                            </TabPane>
                        )
                    })}
                </Tabs>
            </div>
        </>
    );
}

export default PaymentComponent;
