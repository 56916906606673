/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import axios from "axios";
import {CSSTransition} from "react-transition-group";
import {ReactComponent as EmailIcon} from "../../../../../Assets/images/svg/icon-email.svg";
import Loader from "../../../../partials/loader";

const apiUrl = process.env.REACT_APP_API;

const PaypalPayment = (props) => {
    const {bookId, couponCode} = props
    const [emailState, setEmailState] = useState({
        email: '',
        error: false,
        errorMessage: ''
    })
    const [loader, loaderState] = useState(false)

    useEffect(() => {
        return () => {

        }
    }, []);

    const emailValidation = (element) => {
        const {value} = element
        const regex = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
        if (!value || regex.test(value) === false) {
            setEmailState(() => {
                return {
                    email: '',
                    errorMessage: 'Email is not valid',
                    error: true
                }
            });
            return false;
        } else {
            setEmailState(() => {
                return {
                    email: value,
                    error: false,
                    errorMessage: ''
                }
            });
        }

    }

    const onSubmit = (element) => {
        const {email, error} = emailState
        const Button = element.target
        if (email && !error) {
            Button.disabled = true
            loaderState(true);
            sendSubscribeInfo(bookId).then(res => {
                Button.disabled = false
                if (res && res.code === 200) {
                    window.location.href = res.redirectUrl
                }
            })
        } else {
            setEmailState(prevState => {
                return {
                    ...prevState,
                    errorMessage: 'Email cannot be blank.',
                    error: true
                }
            })
        }
    }

    const sendSubscribeInfo = async bookId => {
        const postData = {
            email: emailState.email,
            bookId: bookId,
            method: "paypal",
            coupon: couponCode
        }

        const res = await axios.post(`${apiUrl}/store/purchase`, postData);

        let {data} = res;

        if (data.error === null) {
            return {
                code: 200,
                redirectUrl: data.payload.redirectUrl
            }
        }
    }

    const {error, errorMessage} = emailState

    return (
        <>
            {loader && (<Loader type="transparent"/>)}
            <div className={`form-box form-box-paypal`}>
                <h5>
                    Please make sure you have indicated the correct email address.
                    We will email you a link to your printable pdf book.
                </h5>
                <div className={`field-row field-row-paypal`}>
                    <div className={`field-box field-box-paypal`}>
                        <input placeholder="Email"
                               className={`with-border with-icon ${error ? 'has-error' : ''}`}
                               type="email"
                               name="email" onChange={event => {
                            if (event.target.value === '') {
                                setEmailState(() => {
                                    return {
                                        email: '',
                                        error: false,
                                        errorMessage: ''
                                    }
                                })
                            } else {
                                emailValidation(event.target)
                            }
                        }}/>
                        <div className="icon icon-email">
                            <EmailIcon/>
                        </div>
                        <CSSTransition
                            in={error}
                            timeout={300}
                            classNames="dropdown-transition"
                            unmountOnExit
                        >
                            <div
                                className="message-field-label message-error">{errorMessage}</div>
                        </CSSTransition>
                    </div>
                    <h5>
                        @SpacePandaMath. The unauthorized distribution of this digital book is strictly prohibited.
                        By purchasing this book, I acknowledge that this book is for my personal use only, and I cannot pass it to someone else.
                    </h5>

                    <button className="btn btn-checkout bg-green color-white"
                            onClick={element => onSubmit(element)}>Proceed to Paypal checkout
                    </button>
                </div>
            </div>
        </>
    );
}

export default PaypalPayment;
