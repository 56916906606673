/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import Loader from "../../../../partials/loader";

const apiUrl = process.env.REACT_APP_API;
const qs = require('qs');

function PaypalSuccess() {
    const [empty, emptyState] = useState(true);
    const [loader, loaderState] = useState(true)
    const [pageData, pageDataState] = useState({
        title: '',
        description: '',
        success: false
    })
    useEffect(() => {
        document.body.className = 'page-payment view-success';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        getPaymentData(params).then(res => {
            loaderState(false);
            emptyState(false);
            const {code, message} = res
            if (code === 200) {
                pageDataState(() => {
                    return {
                        success: true,
                        title: 'Payment successful',
                        description: 'Check your email'
                    }
                })
            } else {
                pageDataState(() => {
                    return {
                        success: false,
                        title: message,
                        description: `Please contact our support: <a href="mailto:team@spacepandamath.org" rel="noreferrer">team@spacepandamath.org</a>`
                    }
                })
            }
        })
        return () => {
            emptyState(true);
        }
    }, []);

    const getPaymentData = async (params) => {
        const res = await axios.get(`${apiUrl}/store/paypal/success`, {
            params: params
        });

        let {data} = res;

        if (data.error === null) {
            return {
                code: 200,
                data: data.payload
            }
        } else {
            return {
                code: 400,
                message: data.error
            }
        }
    }
    const {title, description, success} = pageData
    return (
        <>
            {loader && (<Loader/>)}
            <page-container>
                {!empty && (
                    <page-wrapper>
                        <h1 className={`color-${success ? 'green' : 'red'}`}>{title}</h1>
                        <div className={`${success ? 'description' : 'additional-data'}`}
                             dangerouslySetInnerHTML={{__html: description}}/>
                    </page-wrapper>
                )}
            </page-container>
        </>
    )
}

export default PaypalSuccess;
