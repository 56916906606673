/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import Loader from "../../../../partials/loader";

function PaypalCancel() {
    const [loader] = useState(true)

    useEffect(() => {
        document.body.className = 'page-payment view-cancel';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {
        window.location.href = '/'
        return () => {
        }
    }, []);

    return (
        <>
            {loader && (<Loader/>)}
        </>
    )
}

export default PaypalCancel;
