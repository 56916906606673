import React, {useEffect, useState} from "react";
import {ReactComponent as Logo} from "../../Assets/images/svg/space-panda-logo.svg";

const Header = () => {
    const [state, setState] = useState({
        showModal: false,
        modalType: null
    })
    useEffect(() => {
        return () => {

        }
    })
    let {showMenu} = state
    return (
        <>
            <header className={showMenu ? 'menu-open' : ''}>
                <header-wrapper>
                    <header-logo>
                        <a href="https://www.spacepandamath.org/" rel="noopener noreferrer" aria-label="Logo" target="_blank"
                           onClick={e => {
                               // if (window.location.pathname === '/') {
                               //     e.preventDefault()
                               // }
                               document.body.classList.remove('overflow-hidden')

                               setState(prevState => {
                                   return {
                                       ...prevState,
                                       showMenu: false
                                   }
                               })
                               window.scroll({
                                   top: 0,
                                   left: 0,
                                   behavior: "smooth"
                               });
                           }}>
                            <Logo/>
                        </a>
                    </header-logo>

                    <header-menu>
                        <header-menu-item class="button-wrap">
                        </header-menu-item>
                    </header-menu>
                </header-wrapper>
            </header>
        </>

    );
}

export default Header;
