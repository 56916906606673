/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';

function StripeCancel() {

    useEffect(() => {
        document.body.className = 'page-payment view-cancel';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {

        return () => {
        }
    }, []);


    return (
        <>
            <page-container>
                <page-wrapper>
                    <h1 className="color-red">Payment Rejected</h1>
                    <div className="additional-data">Please contact our support: <a href="mailto:team@spacepandamath.org" rel="noreferrer">team@spacepandamath.org</a>.</div>
                </page-wrapper>
            </page-container>
        </>
    )
}

export default StripeCancel;
