/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useRef} from 'react';
import {CSSTransition} from "react-transition-group";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;

function InterestedForm(props) {
    const {bookId} = props
    const emailInputRef = useRef()
    const [emailState, setEmailState] = useState({
        email: '',
        error: false,
        errorMessage: '',
        successMessage: ''
    })

    useEffect(() => {
        return () => {
            document.body.className = '';
        }
    }, []);

    const emailValidation = (element) => {
        const {value} = element
        const regex = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
        if (!value || regex.test(value) === false) {
            setEmailState(prevState => {
                return {
                    ...prevState,
                    email: '',
                    errorMessage: "Email is not valid",
                    error: true
                }
            });
            return false;
        } else {
            setEmailState(prevState => {
                return {
                    ...prevState,
                    email: value,
                    error: false,
                    errorMessage: "",
                }
            });
        }

    }

    const onSubmit = (element, id) => {
        const {email, error} = emailState
        const Button = element.target
        if (email && !error) {
            Button.disabled = true
            sendSubscribeInfo(id).then(res => {
                const {success, message} = res
                Button.disabled = false
                if (success) {
                    emailInputRef.current.value = ""
                    setEmailState(prevState => {
                        return {
                            ...prevState,
                            email: '',
                            successMessage: 'Thank you! you will be notified when the solution book is available.'
                        }
                    })
                } else {
                    setEmailState(prevState => {
                        return {
                            ...prevState,
                            errorMessage: message
                        }
                    })
                }
            })
        } else {
            setEmailState(prevState => {
                return {
                    ...prevState,
                    errorMessage: 'Email cannot be blank.',
                    error: true
                }
            })
        }
    }

    const sendSubscribeInfo = async (id) => {
        const postData = {
            email: emailState.email,
            bookId: id
        }

        const res = await axios.post(`${apiUrl}/store/books/interested`, postData);

        let {data} = res;

        if (data.error === null) {
            return {
                success: true,
            }
        } else {
            return {
                success: false,
                message: data.error.message
            }
        }
    }

    const {error, errorMessage, successMessage} = emailState
    return (
        <>
            <div className="product-coming">
                Coming Soon
            </div>
            <div className="form-box form-box-subscribe">
                <div className="form-title">
                    Be the first to know and get 20% discount
                </div>

                <div className="field-row field-row-subscribe">
                    <div className="field-box field-box-subscribe">
                        <input placeholder="Email address"
                               ref={emailInputRef}
                               className={`${error ? 'has-error' : ''}`}
                               type="email"
                               name="email" onChange={event => {
                            if (event.target.value === '') {
                                setEmailState(prevState => {
                                    return {
                                        ...prevState,
                                        email: '',
                                        error: false,
                                        errorMessage: ''
                                    }
                                })
                            } else {
                                emailValidation(event.target)
                            }
                        }}/>
                        <CSSTransition
                            in={error}
                            timeout={300}
                            classNames="dropdown-transition"
                            unmountOnExit
                        >
                            <div className="message-field-label message-error">{errorMessage}</div>
                        </CSSTransition>
                        {successMessage !== '' ? (
                            <div className="message-field-label message-success">{successMessage}</div>) : ''}
                    </div>

                    <button className="btn btn-subscribe bg-green color-white"
                            onClick={element => onSubmit(element, bookId)}>Notify me!
                    </button>
                </div>
            </div>
        </>
    )
}

export default InterestedForm;
