/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Stripe from "./stripe";
import axios from "axios";
import Loader from "../../../../partials/loader";

const apiUrl = process.env.REACT_APP_API;

const StripePayment = (props) => {
    const {price, discount, bookId,couponCode} = props
    const [loader, loaderState] = useState(false)

    useEffect(() => {
        return () => {

        }
    }, []);

    const Purchase = async (purchaseData) => {
        const {token, email} = purchaseData
        const postData = {
            token,
            email: email,
            bookId: bookId,
            method: "stripe",
            coupon: couponCode
        }

        const res = await axios.post(`${apiUrl}/store/purchase`, postData);

        let {data} = res;

        if (data.error === null) {
            return {
                code: 200,
            }
        } else {
            return {
                code: 400,
                message: data.error.message
            }
        }
    }

    const handleData = data => {
        loaderState(true);
        Purchase(data).then(res => {
            const {code} = res
            if (code === 200) {
                window.location.href = '/payment/stripe/success'
            } else {
                window.location.href = '/payment/stripe/cancel'
            }
        })
    }

    return (
        <>
            {loader && (<Loader type="transparent"/>)}
            <Stripe handleData={handleData} price={price} discount={discount}/>
        </>
    );
}

export default StripePayment;
