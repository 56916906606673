/* eslint-disable no-template-curly-in-string */

const Loader = (props) => {
    const {type} = props
    return (
        <div className={`loader-box ${type}`}>
            <span className="loader"><span className="loader-inner"/></span>
        </div>
    )
}

export default Loader
