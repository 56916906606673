/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import MetaTags from "../../../metaTags";
import {Link} from "react-router-dom";
import InterestedForm from "../../partials/interestedForm";
import Loader from "../../partials/loader";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const apiUrl = process.env.REACT_APP_API;

function Product(props) {
    const {productKey} = props.match.params
    const [product, productState] = useState({})
    const [empty, emptyState] = useState(true);
    const [loader, loaderState] = useState(true)
    const [state, setState] = useState({
        showLightBox: false,
        photoUrl: '',
    })
    useEffect(() => {
        document.body.className = 'page-product';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {
        getProductData(productKey).then(res => {
            const {code, data} = res
            if (code === 200) {
                productState(data)
                emptyState(false);
                loaderState(false);
            } else {
                window.location.href = '/'
            }
        })
        return () => {
            productState({})
            emptyState(true);
        }
    }, [productKey]);

    const getProductData = async value => {
        const res = await axios.get(`${apiUrl}/store/books/${value}`);

        let {data} = res;

        if (data.error === null) {
            return {
                code: 200,
                data: data.payload
            }
        } else {
            return {
                code: 400
            }
        }
    }
    const handleImageClick = url => {
        setState(prevState => {
            return {
                ...prevState,
                photoUrl: url,
                showLightBox: true
            }
        })
    }
    const {name, shortDescr, editorNotes, longDescr, price, discount, url, url2, key, isActive, id} = product

    const {
        showLightBox, photoUrl
    } = state

    return (
        <>
            <MetaTags page="product"/>
            {loader && (<Loader/>)}
            <page-container>
                {!empty && (
                    <page-wrapper>
                        <div className="product-image">
                            <img src={url} alt={name} onClick={() => {
                                handleImageClick(url)
                            }}/>
                            {url2 && (<img src={url2} alt={name} onClick={() => {
                                handleImageClick(url2)
                            }}/>)}
                        </div>

                        <div className="product-info">
                            <div className="product-title">{name}</div>
                            <div className="product-short-description">{shortDescr}</div>
                            {!isActive ? (
                                <InterestedForm bookId={id}/>
                            ) : discount ? (
                                <div className="product-price-box">
                                    <div className="product-price old-price">${price.toFixed(2)}</div>
                                    <div
                                        className="product-price new-price">${(price - discount).toFixed(2)}</div>
                                </div>
                            ) : (
                                <div className="product-price-box">
                                    <div className="product-price">${price.toFixed(2)}</div>
                                </div>
                            )}
                            {isActive && (
                                <>
                                    <Link to={`/checkout/${key}`} className="btn btn-medium color-white bg-green">Buy
                                        Now</Link>

                                    <div className="product-description"
                                         dangerouslySetInnerHTML={{__html: editorNotes.replace(/(\r\n|\n|\r)/gm, "<br />")}}/>
                                    <div className="product-description"
                                         dangerouslySetInnerHTML={{__html: longDescr.replace(/(\r\n|\n|\r)/gm, "<br />")}}/>
                                </>
                            )}
                        </div>
                    </page-wrapper>
                )}
            </page-container>

            {showLightBox && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={photoUrl}
                    onCloseRequest={() => setState(prevState => {
                        return {
                            ...prevState,
                            showLightBox: false,
                            photoUrl: ''
                        }
                    })}
                />
            )}
        </>
    )
}

export default Product;
