/* eslint-disable no-template-curly-in-string */
import React from "react";
import CardForm from './cardForm'
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);
const Stripe = (props) => {
    let {price, discount} = props
    const handleData = data => {
        props.handleData(data)
    }
    return (
        <Elements stripe={stripePromise}>
            <CardForm price={price} discount={discount} handleData={handleData}/>
        </Elements>
    )
}

export default Stripe;
