/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';

function StripeSuccess() {
    useEffect(() => {
        document.body.className = 'page-payment view-success';
        return () => {
            document.body.className = '';
        }
    }, []);

    useEffect(() => {

        return () => {
        }
    }, []);

    return (
        <>
            <page-container>
                <page-wrapper>
                    <h1 className="color-green">Payment successful</h1>
                    <div className="description">Check your email</div>
                </page-wrapper>
            </page-container>
        </>
    )
}

export default StripeSuccess;
